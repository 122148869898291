@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Reference: https://github.com/shadcn-ui/ui/discussions/5839 */
  /* Reference https://github.com/shadcn-ui/ui/discussions/3257#discussion-6435902 */
  .prose {
    h1:not(h1.not-prose, .not-prose h1) {
      @apply text-h1;
    }

    h2:not(h2.not-prose, .not-prose h2) {
      @apply pb-2 text-h2 first:mt-0;
    }

    h3:not(.not-prose h3, .not-prose h3) {
      @apply text-h3;
    }

    h4:not(.not-prose h4, .not-prose h4) {
      @apply text-h4;
    }

    p:not(p.not-prose, .not-prose p) {
      @apply text-p [&:not(:first-child)]:mt-6;
    }

    strong:not(strong.not-prose, .not-prose strong) {
      @apply text-bold;
    }

    em:not(em.not-prose, .not-prose em) {
      @apply text-italic;
    }

    blockquote:not(blockquote.not-prose, .not-prose blockquote) {
      @apply mt-6 text-blockquote;
    }

    /* The prose class adds '""' content. We do not want that. */
    :where(blockquote p:first-of-type):not(
        :where([class~="not-prose"], [class~="not-prose"] *)
      )::before {
      content: "";
      display: none;
    }

    /* The prose class adds '""' content. We do not want that. */
    :where(blockquote p:first-of-type):not(
        :where([class~="not-prose"], [class~="not-prose"] *)
      )::after {
      content: "";
      display: none;
    }

    a:not(a.not-prose, .not-prose a) {
      @apply text-a;
    }

    table:not(table.not-prose, .not-prose table) {
      @apply table;
    }

    ul:not(ul.not-prose, .not-prose ul) {
      @apply my-6 ml-6 list;
    }

    ol:not(ol.not-prose, .not-prose ol) {
      @apply my-6 ml-6 list;
    }

    pre:has(> code) {
      @apply text-left;
    }

    code:not(code.not-prose, .not-prose code) {
      @apply text-inline-code;
    }
  }
}

.uploadcare--dialog__close .uploadcare--icon {
  @apply fill-card-foreground-subtle;
}
.uploadcare--dialog__panel {
  @apply bg-card text-card-foreground;
}

.uploadcare--menu__items {
  @apply bg-card contrast-75;
}

.uploadcare--menu__item {
  @apply bg-card contrast-75 hover:contrast-200;
}

.uploadcare--menu__item_current {
  @apply bg-card contrast-200;
}

.uploadcare--menu__icon {
  @apply fill-card-foreground;
}

.uploadcare--powered-by {
  display: none;
}

.uploadcare--panel__footer {
  @apply bg-zi-white;
}

.uploadcare--file_status_uploading .uploadcare--file__preview {
  @apply bg-primary !important;
  color: hsl(var(--primary)) !important;
}

.uploadcare--progressbar__value {
  @apply bg-primary;
}

.uploadcare--progress_type_canvas {
  color: hsl(var(--primary));
}

@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    @apply m-0 text-zi-text;
  }

  .interfaces-theme body,
  body.interfaces-theme {
    @apply text-foreground;
  }

  /* Hide the "My Zaps" button in the top nav in the listing page */
  #back-to-app {
    display: none !important;
  }
}

@layer components {
  .custom-scrollbar {
    * {
      scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
    }

    *::-webkit-scrollbar {
      @apply w-1;
    }

    *::-webkit-scrollbar-track {
      @apply bg-transparent;
    }

    *::-webkit-scrollbar-thumb {
      @apply rounded-sm bg-black/10;
    }
  }

  .interfaces-theme .custom-scrollbar,
  .interfaces-theme.custom-scrollbar {
    *::-webkit-scrollbar-thumb {
      @apply rounded-small bg-foreground/10;
    }
  }

  .custom-uploadcare-widget {
    .uploadcare--widget__button {
      @apply cursor-pointer bg-zi-primary text-zi-white transition-all duration-150 hover:brightness-110 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zi-primary focus-visible:ring-offset-2 focus-visible:ring-offset-zi-pageBackground;
    }
  }

  .interfaces-theme .custom-uploadcare-widget .uploadcare--widget__button,
  .interfaces-theme.custom-uploadcare-widget .uploadcare--widget__button {
    @apply bg-primary text-primary-foreground focus-visible:ring-primary focus-visible:ring-offset-background;
  }

  /**
    * ===============================================
    * Dot Falling animation classes taken from
    * https://nzbin.github.io/three-dots/
    * (used by @zapier/chatbots-tailwind)
    * ===============================================
    */
  .dot-falling {
    position: relative;
    left: -9999px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: hsl(var(--zi-primary));
    color: hsl(var(--zi-primary));
    box-shadow: 9999px 0 0 0 hsl(var(--zi-primary));
    animation: dot-falling 1s infinite linear;
    animation-delay: 0.1s;
  }
  .dot-falling::before,
  .dot-falling::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-falling::before {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: hsl(var(--zi-primary));
    color: hsl(var(--zi-primary));
    box-shadow: 9990px 0 0 0 hsl(var(--zi-primary));
    animation: dot-falling-before 1s infinite linear;
    animation-delay: 0s;
  }
  .dot-falling::after {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: hsl(var(--zi-primary));
    color: hsl(var(--zi-primary));
    box-shadow: 10008px 0 0 0 hsl(var(--zi-primary));
    animation: dot-falling-after 1s infinite linear;
    animation-delay: 0.2s;
  }

  @keyframes dot-falling {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 hsl(var(--zi-primary));
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
  @keyframes dot-falling-before {
    0% {
      box-shadow: 9990px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9990px 0 0 0 hsl(var(--zi-primary));
    }
    100% {
      box-shadow: 9990px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
  @keyframes dot-falling-after {
    0% {
      box-shadow: 10008px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 10008px 0 0 0 hsl(var(--zi-primary));
    }
    100% {
      box-shadow: 10008px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }

  /**
    * ===============================================
    * Dot Falling (used by @zapier/chatbots-tailwind)
    * ===============================================
    */
  .dot-falling--v2 {
    --dot-size: 5px;
    position: relative;
    left: -9999px;
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: calc(var(--dot-size) / 2);
    background-color: hsl(var(--primary));
    color: hsl(var(--primary));
    box-shadow: 9999px 0 0 0 hsl(var(--primary));
    animation: dot-falling--v2 1s infinite linear;
    animation-delay: 0.1s;
  }
  .dot-falling--v2::before,
  .dot-falling--v2::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-falling--v2::before {
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: calc(var(--dot-size) / 2);
    background-color: hsl(var(--primary));
    color: hsl(var(--primary));
    box-shadow: 9990px 0 0 0 hsl(var(--primary));
    animation: dot-falling-before--v2 1s infinite linear;
    animation-delay: 0s;
  }
  .dot-falling--v2::after {
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: calc(var(--dot-size) / 2);
    background-color: hsl(var(--primary));
    color: hsl(var(--primary));
    box-shadow: 10008px 0 0 0 hsl(var(--primary));
    animation: dot-falling-after--v2 1s infinite linear;
    animation-delay: 0.2s;
  }

  @keyframes dot-falling--v2 {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 hsl(var(--primary));
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
  @keyframes dot-falling-before--v2 {
    0% {
      box-shadow: 9990px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9990px 0 0 0 hsl(var(--primary));
    }
    100% {
      box-shadow: 9990px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
  @keyframes dot-falling-after--v2 {
    0% {
      box-shadow: 10008px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 10008px 0 0 0 hsl(var(--primary));
    }
    100% {
      box-shadow: 10008px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }
}
